import React from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css';
import img1 from '../assets/nugget-header.png';

function Navbar() {
    return (
        <>
            <nav className='navbar'>
                <div className='navbar-container'>
                    <Link to='/' className='navbar-logo'>
                        <img className="card__image" src={img1} alt="not shown"/>
                    </Link>
                </div>
            </nav>
        </>
    )
}

export default Navbar;
