import React, { useState, useEffect } from 'react';//
import Web3 from "web3";
import { Button } from './Button';
import './Mint.css';
import {ADDRESS, ABI} from "../config.js"






function Mint() {

    // FOR WALLET
    const [signedIn, setSignedIn] = useState(false)

    const [walletAddress, setWalletAddress] = useState(null) //

    // FOR MINTING
    const [how_many_nuggets, set_how_many_nuggets] = useState(1)

    const [nuggetContract, setNuggetContract] = useState(null)//

    // INFO FROM SMART Contract

    const [totalSupply, setTotalSupply] = useState(0)

    const [saleStarted, setSaleStarted] = useState(false)//

    const [getPrice, setNuggetPrice] = useState(0)//

//    useEffect( async() => {
//
//        signIn()
//
//    }, [])

    function signIn() {
        if (typeof window.web3 !== 'undefined') {
            // Use existing gateway
            window.web3 = new Web3(window.ethereum);
        } else {
            alert("No Ethereum interface injected into browser. Read-only access");
        }

        window.ethereum.enable()
        .then(function (accounts) {
            window.web3.eth.net.getNetworkType()
            // checks if connected network is mainnet (change this to rinkeby if you wanna test on testnet)
            .then((network) => {console.log(network);if(network != "main"){alert("You are on " + network+ " network. Change network to mainnet or you won't be able to do anything here")} });
            let wallet = accounts[0]
            setWalletAddress(wallet)
            setSignedIn(true)
            callContractData(wallet)
        })
        .catch(function (error) {
            // Handle error. Likely the user rejected the login
            console.error(error)
        })
    }
    //
    function signOut() {
        setSignedIn(false)
    }

    async function callContractData(wallet) {
        // let balance = await web3.eth.getBalance(wallet);
        // setWalletBalance(balance)
        const nuggetContract = new window.web3.eth.Contract(ABI, ADDRESS)
        setNuggetContract(nuggetContract)

        const salebool = await nuggetContract.methods.saleIsActive().call()
        // console.log("saleisActive" , salebool)
        setSaleStarted(salebool)

        const totalSupply = await nuggetContract.methods.totalSupply().call()
        setTotalSupply(totalSupply)

        const getPrice = await nuggetContract.methods.getPrice().call()
        setNuggetPrice(getPrice)

    }

    async function mintDuck(how_many_nuggets) {
        if (nuggetContract) {

            const price = Number(getPrice)  * how_many_nuggets

            const gasAmount = await nuggetContract.methods.mintNerdyNuggets(how_many_nuggets).estimateGas({from: walletAddress, value: price})
            console.log("estimated gas",gasAmount)

            console.log({from: walletAddress, value: price})

            nuggetContract.methods
                .mintNerdyNuggets(how_many_nuggets)
                .send({from: walletAddress, value: price, gas: String(gasAmount), type: "0x2"})
                .on('transactionHash', function(hash){
                    console.log("transactionHash", hash)
                })

        } else {
            console.log("Wallet not connected")
        }
    };



const handleClick = () => {
    //console.log('Hello there!');
    mintDuck(how_many_nuggets)
};
return (
    <div className='new-body'>
        <div className='hero-container-Private'>
            <div className="hero-btns">
            {!signedIn ?
            <Button
                onClick={signIn}
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--medium'
            >Connect Wallet with Metamask
            </Button>
            : <Button
                onClick={signOut}
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--medium'
            >Wallet Connected: {walletAddress}
            </Button>
            }
            </div>
            <h2>NUGGET MINTING</h2>
            <p>Mint a Nerdy Nugget here!</p>
            <div id="mint" className="flex">

            <span className="flex">GET</span>

            <input
                type="number"
                min="1"
                max="10"
                value={how_many_nuggets}
                onChange={ e => set_how_many_nuggets(e.target.value) }
                name=""
            />
            <span className="flex">NUGGETS!</span>

            </div>
            <div className='hero-btns'>
            {saleStarted ?
            <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
                onClick={handleClick}
            >
            MINT {how_many_nuggets} nuggets for {(getPrice * how_many_nuggets) / (10 ** 18)} ETH + GAS
            </Button>
            : <Button
                className='btns'
                buttonStyle='btn--outline'
                buttonSize='btn--large'
            >SALE IS NOT ACTIVE OR NO WALLET IS CONNECTED
            </Button>
            }
            </div>
            <p><a href="https://etherscan.io/address/0xb45F2ba6b25b8f326f0562905338b3Aa00D07640" link="black" vlink="red">0xb45F2ba6b25b8f326f0562905338b3Aa00D07640</a></p>
        </div>
    </div>



);
}
export default Mint;
