import React from 'react';
import '../App.css';
import Mint from './Mint';

function Home() {
    return (
        <>
            <Mint />
        </>
    );
}
export default Home;
